<!--
 * @Description: 规则引擎 - 规则列表
 * @Author: kecraft
 * @Date: 2024-03-07 09:30:52
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-20 09:10:52
 * @FilePath: /impact-iotos-console/src/views/rules/list/index.vue
-->

<template>
  <div class="rules-list">
    <div>
      <div class="rules-box">
        <div class="big-title">
          <div></div>
          规则引擎
        </div>
        <div class="top-container">
          <div class="search-conatiner">
            <div class="form-item">
              <div class="is-title">选择产品</div>
              <el-select v-model="query.pk" placeholder="请选择" class="width260" @change="search" clearable>
                <el-option v-for="item in pkList" :key="item.pk" :label="item.name" :value="item.pk" />
              </el-select>
            </div>
            <div class="form-item">
              <el-input v-model="query.desc" class="input-item" placeholder="请输入规则描述" :prefix-icon="Search" />
            </div>
            <div class="form-item">
              <div class="search-btn" @click="search" v-domPreventReClick>搜索</div>
              <div class="reset-btn" @click="handleReset" v-domPreventReClick>重置</div>
            </div>
          </div>
          <div class="btn-normal">
            <div class="btn-green" @click="handleAdd" v-domPreventReClick>
              <img src="@/assets/icon/add.png">
              创建规则
            </div>
          </div>
        </div>

        <div class="tables">
          <el-table stripe :data="rulesList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
            <el-table-column width="80" label="序号" align="center">
              <template #default="scope">
                <span>{{
                scope.$index + 1
              }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="120" label="所属产品" prop="name" align="center">
              <template #default="scope">
                <div class="product-content" @click="handleProduct(scope.row.pkList)" v-domPreventReClick> {{
                dealProduct(scope.row.pks) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="160" label="规则描述" prop="desc" align="center" show-overflow-tooltip />
            <el-table-column width="100" label="类型" prop="destType" align="center" show-overflow-tooltip>
              <template #default="scope">
                {{ dealDestType(scope.row.destType) }}
              </template></el-table-column>
            <el-table-column width="220" label="规则ID" prop="ruleId" align="center" show-overflow-tooltip />
            <el-table-column min-width="160" label="创建时间" prop="createdDate" align="center" />
            <el-table-column min-width="120" label="是否启用" prop="enable" align="center">
              <template #default="scope">
                <div class="status-container">
                  <el-switch v-model="scope.row.enable" class="ml-2" @click="handleSwitchChange(scope.row)"
                    style="--el-switch-on-color: #13ce66;margin-right:10px;" />
                  {{ scope.row.enable ? "已启用" : "已禁用" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="220" label="操作" align="center">
              <template #default="scope">
                <div class="column-btn">
                  <div class="options">
                    <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleDetail(scope.row)"
                      v-domPreventReClick>
                      查看
                    </span>
                    <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleEdit(scope.row)"
                      v-domPreventReClick>
                      修改
                    </span>
                    <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleDel(scope.row)"
                      v-domPreventReClick>
                      删除
                    </span>
                    <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleHistory(scope.row)"
                      v-domPreventReClick>
                      历史记录
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="fooner-conatiner">
          <pagination :total="total" @pageChange="getList" class="pagination" />
        </div>
      </div>
    </div>
    <productDialog v-if="isProduct" ref="productDialogRef" @close="() => isProduct = false" />
  </div>
</template>

<script setup>
import { Search } from '@element-plus/icons-vue';
import pagination from "@/components/control/pagination";
import productDialog from './productDialog.vue';
import { reactive, ref, onMounted, getCurrentInstance, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from "element-plus";
import api from "@/api/api"
const router = useRouter()
const pkList = ref([])
const isProduct = ref(false);
const rulesList = ref([]);
const isload = ref(false);
const taskList = ref([]);
const total = ref(0);
let currentInstance = "";
const query = reactive({
  pk: "",
  desc: "",
  page: 0,
  size: 10,
})
onMounted(() => {
  currentInstance = getCurrentInstance();
})
const getProducts = () => {
  api.getRuleTaskType().then(res => {
    if (res.code === "0") {
      taskList.value = res.res.data;
    }
  })
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      const all = {
        pk: "all",
        name: "全部",
      }
      query.pk = "all"
      pkList.value = [all, ...res.res.data];
    }
  })
}

getProducts();
const handleAdd = () => {
  router.push({
    path: "/rules/add",
    query: {
      type: "add",
    }
  })
}

const handleProduct = (val) => {
  isProduct.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.productDialogRef.init(val);
  });
}
const search = () => {
  isload.value = true;
  if (query.pk === "all" || !query.pk) {
    api.getRulesByPage({
      desc: query.desc,
      page: query.page,
      size: query.size,
    }).then(res => {
      if (res.code === "0") {
        const { totalElements, content } = res.res.data;
        const all = content
        all.forEach(item => {
          const list = [];
          const allPks = pkList.value;
          item.pks.forEach(a => {
            allPks.forEach(pk => {
              if (a === pk.pk) {
                list.push(pk.name)
              }
            })
          })
          item.pkList = list;
        })
        total.value = totalElements;
        rulesList.value = content;
        isload.value = false;
      }
    })
  } else {
    api.getRulesByPage(query).then(res => {
      if (res.code === "0") {
        const { totalElements, content } = res.res.data;
        const all = content
        all.forEach(item => {
          const list = [];
          const allPks = pkList.value;
          item.pks.forEach(a => {
            allPks.forEach(pk => {
              if (a === pk.pk) {
                list.push(pk.name)
              }
            })
          })
          item.pkList = list;
        })
        total.value = totalElements;
        rulesList.value = content;
        isload.value = false;
      }
    })
  }
}
search();
const handleReset = () => {
  query.pk = "all";
  query.desc = "";
  search();
}

const dealProduct = (pks) => {
  const list = [];
  const allPks = pkList.value;
  pks.forEach(item => {
    allPks.forEach(pk => {
      if (item === pk.pk) {
        list.push(pk.name)
      }
    })
  })
  return list.join(" ")
}
const dealDestType = (type) => {
  let str = ""
  taskList.value.forEach(item => {
    if (item.name === type) {
      str = item.type
    }
  })
  return str;
}
const getList = (v) => {
  query.page = v.pageNum - 1
  query.size = v.pageSize
  search();
}

const handleDetail = (row) => {
  const { ruleId } = row
  router.push({
    path: "/rules/add",
    query: {
      type: "view",
      ruleId,
    }
  })
}

const handleHistory = (row) => {
  const { ruleId } = row
  router.push({
    path: "/rules/history",
    query: {
      ruleId,
    }
  })
}
const handleEdit = (row) => {
  const { ruleId } = row
  router.push({
    path: "/rules/add",
    query: {
      type: "edit",
      ruleId,
    }
  })
}
const handleDel = (row) => {
  ElMessageBox.confirm("确定删除该规则吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.ruleDelete(row.ruleId).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("删除成功!")
        search()
      }
    })
  })
}
const handleSwitchChange = (row) => {
  const { ruleId, enable } = row;
  const older = enable;
  ElMessageBox.confirm(`确定${older ? "启用" : "禁用"}该规则吗？`, "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.enableRule(ruleId, enable).then(res => {
      if (res.code === "0") {
        if (!older) {
          ElMessage.success("禁用成功！")
        } else {
          ElMessage.success("启用成功！")
        }
        search();
      } else {
        rulesList.value.forEach(item => {
          if (item.ruleId === ruleId) {
            item.enable = !older;
          }
        })
      }
    })
  }).catch(() => {
    rulesList.value.forEach(item => {
      if (item.ruleId === ruleId) {
        item.enable = !older;
      }
    })
  })
}

</script>

<style lang="less" scoped>
.width260 {
  width: 260px;
  margin-right: 30px;
  border-radius: 4px;
  margin-right: 6px;
}

.btn-green {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 36px;
  border-radius: 4px;
  background: #30AE7D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}

.search-conatiner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .is-title {
    display: inline-block;
    font-size: 16px;
    color: #606266;
    margin-right: 10px;
  }

  .form-item {
    display: flex;
    align-items: center;
  }

  .input-item {
    width: 260px;
  }
}

.big-title {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #367CC5;
  margin-bottom: 20px;

  &>div {
    width: 3px;
    height: 17px;
    background: #367CC5;
    margin-right: 7px;
  }
}

.rules-list {
  box-sizing: border-box;

  &>div {
    min-height: calc(100vh - 76px);
    background: #FAFAFB;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 0;

    .rules-box {
      width: 100%;
      height: calc(100vh - 76px);
      background: #ffffff;
      box-sizing: border-box;
      padding: 18px 40px;
    }
  }
}

.top-container {
  box-sizing: border-box;
  // padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.search-btn {
  margin-left: 20px;
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background: #367CC5;
  font-weight: lighter;
  // border: 1px solid #015ee0;
  font-size: 14px;
  color: #FFFFFF;
  font-size: 16px;
}

.search-btn:hover,
.search-btn:active {
  border: 1px solid #367CC5;
  background: #61A4E9;
  // color: #367CC5;
}

.reset-btn {
  margin-left: 20px;
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;
}

.product-content {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tables {
  box-sizing: border-box;
  margin-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}

.fooner-conatiner {
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>