<template>
  <div>
    <myDialog @close="handleClose" @ok="handleOk" title="所属产品">
      <div class="dialog-box">
        <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
          <div class="product-box" v-if="pks">
            <span class="product-name" v-for="(item, index) of pks" :key="index">
              {{ item }}
            </span>
          </div>
        </el-scrollbar>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { defineExpose, defineEmits, ref } from 'vue';
const emit = defineEmits(["close"]);
const pks = ref([]);
const init = (list) => {
  const all = list;
  pks.value = all;
}
const handleClose = () => {
  emit("close")
}
const handleOk = () => {
  emit("close")
}
defineExpose({ init });
</script>

<style lang="less" scoped>
.dialog-box {
  width: 700px;
  height: 400px;
}

.product-box {
  display: flex;
  flex-wrap: wrap;

  .product-name {
    display: inline-block;
    background: #DFDFDF;
    padding: 5px 7px;
    line-height: 24px;
    border-radius: 4px;
    color: #606266;
    margin-right: 6px;
    margin-bottom: 10px;
  }
}
</style>